import isEqual from 'date-fns/isEqual'
import parseISO from 'date-fns/parseISO'
import set from 'date-fns/set'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import { compareDatesWithoutTime, newDateMidnight } from './helpers/date-fns'

export function createIntervalFromTime(interval, date) {
  const startHour = interval.s.split(':')[0]
  const startMinute = interval.s.split(':')[1]
  const endHour = interval.e.split(':')[0]
  const endMinute = interval.e.split(':')[1]
  const startDate = set(new Date(date), {
    hours: startHour,
    minutes: startMinute,
    seconds: 0,
    milliseconds: 0
  })
  const endDate = set(new Date(date), {
    hours: endHour,
    minutes: endMinute,
    seconds: 0,
    milliseconds: 0
  })
  return { s: startDate, e: endDate }
}

// Return index of overlaping slot
export const findOverlappingSlotIndex = (currentTimeSlots, slot) => {
  return currentTimeSlots.findIndex((timeSlot) =>
    areIntervalsOverlapping(
      {
        start: timeSlot.interval.s,
        end: timeSlot.interval.e
      },
      {
        start: slot.interval.s,
        end: slot.interval.e
      }
    )
  )
}

export const findMatchingSlotDayIndex = (currentTimeSlots, slot) =>
  currentTimeSlots.findIndex((timeSlot) => {
    return compareDatesWithoutTime(timeSlot.interval.s, slot.interval.s)
  })

export const getAvailableSlotsOfDay = (slots, ofDay) => {
  if (!Array.isArray(slots) || !ofDay) return []
  const ofDayDate = newDateMidnight(ofDay)
  const availableSlots = slots.filter((slot) =>
    isEqual(newDateMidnight(slot.date), ofDayDate)
  )
  if (!availableSlots) return []
  return availableSlots
}

// gets booking and formats time slots
export const formatBookingTimeSlots = (availableBookingSlots, ofDay) => {
  if (!availableBookingSlots || !ofDay) return []
  const ofDayDate = newDateMidnight(ofDay)
  const slot = availableBookingSlots.find((slot) => {
    return compareDatesWithoutTime(
      newDateMidnight(parseISO(slot.date)),
      ofDayDate
    )
  })

  if (!slot) return {}

  return {
    ...slot,
    timeSlots: slot.timeSlots?.length
      ? slot.timeSlots.map((timeSlot) => {
          return {
            ...timeSlot,
            maxSpots: slot.maxSpots,
            interval: createIntervalFromTime(timeSlot, ofDayDate)
          }
        })
      : []
  }
}

// Gets available booking dates
// <-- Takes
// availableBookingSlots: {
//   '2020-01-01': [
//     {
//       interval: {
//         s: '15:00',
//         e: '16:00'
//       }
//     },
// }
// Returns -->
// ['2020-01-01' ...]

export const getAvailableBookingDates = (availableBookings) => {
  if (!availableBookings) return []
  return availableBookings.map((day) => parseISO(day.date))
}

// Get available dates from timeSlots
export const getAvailableDates = (slots) =>
  slots
    .filter((slot) => slot.date)
    .map((slot) => {
      return newDateMidnight(slot.date)
    })

export const normalizeInterval = (interval) => {
  return {
    s: new Date(interval.s),
    e: new Date(interval.e)
  }
}
