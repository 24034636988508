import { persist } from 'zustand/middleware'
import createContext from 'zustand/context'
import { useLayoutEffect } from 'react'
import create from 'zustand'

// Zustand store
// Example: https://github.com/vercel/next.js/blob/canary/examples/with-zustand/lib/store.js
// More info: https://github.com/pmndrs/zustand/issues/182

// Set up context
const zustandContext = createContext()
export const UserStoreProvider = zustandContext.Provider
export const useUserStore = zustandContext.useStore

// Config
let userStore

// Generate bookings from config

const getDefaultInitialState = () => ({
  phone: null,
  email: null,
  id: null,
  loggedIn: false,
  accessToken: null,
  fullName: null,
  isAdmin: false,
  cookieNoticeDismissed: false
})

function initializeStore(preloadedState = {}) {
  return create(
    persist(
      (set, get) => ({
        ...getDefaultInitialState(),
        ...preloadedState,
        logIn: ({ token, fullName, id, email, phone, isAdmin }) => {
          set({
            loggedIn: true,
            bookingStep: 2, // Skip login step
            accessToken: token,
            fullName: fullName,
            id: id,
            isAdmin,
            email: email,
            ...(phone && { phone })
          })
        },
        logOut: () => {
          set({
            ...getDefaultInitialState(),
            cookieNoticeDismissed: get().cookieNoticeDismissed
          })
        },
        setUserData: ({ email, phone }) => {
          set({
            ...(email && { email }),
            ...(phone && { phone })
          })
        },
        dismissCookieNotice: () => {
          set({
            cookieNoticeDismissed: true
          })
        }
      }),
      {
        name: `user-store@1.5-${process.env.NODE_ENV}`, // Update when store structure changes changes in store
        getStorage: () => localStorage
      }
    )
  )
}

export function useCreateUserStore(serverInitialState) {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initializeStore(serverInitialState)
  }

  const isReusingStore = Boolean(userStore)
  userStore = userStore ?? initializeStore(serverInitialState)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (serverInitialState && isReusingStore) {
      userStore.setState(
        {
          ...userStore.getState(),
          ...serverInitialState
        },
        true
      )
    }
  })

  return () => userStore
}
