import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  Hydrate
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import GlobalMeta from '@/components/Common/Meta/Global'
import NextNprogress from 'nextjs-progressbar'
import React from 'react'
import Script from 'next/script'
import toast from 'react-hot-toast'

// EXPERIMENTAL
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY, {
  locale: 'en-GB'
})

import '@/assets/scss/base.global.scss'

import { Provider as StoreProvider, useCreateStore } from '@/store'
import { UserStoreProvider, useCreateUserStore } from '@/userStore'
import useHasMounted from '@/hooks/useHasMounted'
import dynamic from 'next/dynamic'
const isDev = process.env.NODE_ENV === 'development'

if (isDev) {
  if (typeof window !== 'undefined') {
    // const whyDidYouRender = await import(
    //   '@welldone-software/why-did-you-render'
    // )
    // console.log(
    //   'Applying whyDidYouRender, to help you locate unnecessary re-renders during development. See https://github.com/welldone-software/why-did-you-render'
    // )
    // whyDidYouRender(React, {
    //   trackAllPureComponents: true,
    //   trackHooks: true,
    //   logOwnerReasons: true,
    //   collapseGroups: true
    // })
  }
}

const ToasterDynamic = dynamic(
  () => import('react-hot-toast').then((mod) => mod.Toaster),
  {
    ssr: false
  }
)

const Toaster = React.memo(ToasterDynamic)

function IRLApp({ Component, pageProps }) {
  const createStore = useCreateStore(pageProps.initialZustandState)
  const creatUserStore = useCreateUserStore(pageProps.initialZustandUserState)
  const hasMounted = useHasMounted()
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          defaultOptions: {
            queries: {
              cacheTime: 1000 * 60 * 5 // Refetch data every 5 minutes
            }
          },
          onSuccess: () => {
            // console.info('QueryCache: Success')
          },
          onError: (error, query) => {
            if (query.state.data !== undefined) {
              error?.response?.data?.message &&
                toast.error(
                  `Something went wrong: ${error?.response?.data?.message}`
                )
            }
          }
        })
      })
  )

  const localStoragePersistor =
    typeof window !== 'undefined' &&
    createWebStoragePersistor({
      storage: window.localStorage
    })

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    maxAge: 1000 * 60 * 15 // Invalidate cache in 15 minutes
  })

  return (
    <>
      <Script id="hotjar" strategy="lazyOnload">
        {`(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:2627002,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
      <Script id="crisp" strategy="lazyOnload">
        {`window.$crisp=[];window.CRISP_WEBSITE_ID="fe98985a-cb42-44d8-a2b7-5ee23a1310f0";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
      </Script>
      <UserStoreProvider createStore={creatUserStore}>
        <StoreProvider createStore={createStore}>
          <NextNprogress
            color="#e72568"
            startPosition={0.3}
            stopDelayMs={100}
            height={3}
            showOnShallow={false}
            options={{ showSpinner: false, trickleSpeed: 100 }}
          />
          <GlobalMeta />
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <Elements options={{ locale: 'en-GB' }} stripe={stripePromise}>
                <Component {...pageProps} />
              </Elements>
              {hasMounted && <Toaster />}
              <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
          </QueryClientProvider>
        </StoreProvider>
      </UserStoreProvider>
    </>
  )
}

export default IRLApp
