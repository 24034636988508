import getDayOfYear from 'date-fns/getDayOfYear'
import parseISO from 'date-fns/parseISO'
import set from 'date-fns/set'

// import { DateTime, Interval } from 'luxon'

export function formatDate(date) {
  const d = new Date(date)
  const dtf = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d)
  return (date = `${ye}-${mo}-${da}`)
}

// // Re-creates interval from string. Used when interval comes from localStorage
// // E.g. interval: {s: dateAsString, e: dateAsString} -> interval: {s: DateTime, e: DateTime}
// export function normalizeInterval(interval) {
//   if (!interval || !interval.e || !interval.s) return {}
//   // Don't normalize if correct
//   if (typeof interval.e !== 'string') return interval
//   return new Interval.fromDateTimes(
//     DateTime.fromJSDate(new Date(interval.s)),
//     DateTime.fromJSDate(new Date(interval.e))
//   )
// }

// // Checks if two intervals overlap
// export function intervalsInclude(intervals, intervalToCompare) {
//   if (!Array.isArray(intervals)) return -1

//   // Compare intervals inside intervals array
//   for (let i = 0; i < intervals.length; i++) {
//     if (intervalToCompare.interval.overlaps(intervals[i].interval)) return i
//   }
//   return -1
// }

// There's probably a smarter way to do this
// Split time slots into days and add array of slots for each day
export function splitTimeSlotsByDate(timeSlots) {
  if (!Array.isArray(timeSlots)) return null
  let dates = {}
  for (let i = 0; i < timeSlots.length; i++) {
    const date = parseISO(timeSlots[i].s)
    const formattedDate = formatDate(date)
    const intervals = []

    for (let j = 0; j < timeSlots.length; j++) {
      if (compareDatesWithoutTime(date, parseISO(timeSlots[j].s))) {
        intervals.push(timeSlots[j])
      }
    }
    dates = { ...dates, [formattedDate]: intervals }
  }

  return dates
}

// This is a helper to format the timeslot so it matches regular bookings. See splitTimeSlotsByDate function
// export function splitEventSlotByDate(timeSlot, dateFrom, dateTo) {
//   if (!timeSlot) return null
//   return [
//     {
//       date: `${DateTime.fromJSDate(new Date(dateFrom)).toLocaleString(
//         DateTime.DATE_FULL
//       )} - ${DateTime.fromJSDate(new Date(dateTo)).toLocaleString(
//         DateTime.DATE_FULL
//       )}`,
//       intervals: timeSlot
//     }
//   ]
// }

export const compareDatesWithoutTime = (date1, date2) => {
  if (!date1 || !date2) return null
  return getDayOfYear(date1) === getDayOfYear(date2)
}

export const dateWith0Time = (date) => {
  return date.set({ hour: 0, minute: 0, second: 0 })
}

export const newDateMidnight = (date) => {
  return set(new Date(date), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  })
}
